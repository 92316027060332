import { render, staticRenderFns } from "./RadioCard.vue?vue&type=template&id=c4dbf716&scoped=true&"
import script from "./RadioCard.vue?vue&type=script&setup=true&lang=ts&"
export * from "./RadioCard.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./RadioCard.vue?vue&type=style&index=0&id=c4dbf716&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4dbf716",
  null
  
)

export default component.exports